<template>


          <v-simple-table class="elevation-3">
               <template v-slot:default>
                    <tbody>
                    <tr
                         v-for="item in table"
                         :key="item.label"
                    >
                         <th>{{ item.label }}</th>
                         
                         <td v-if="item.type=='string'">{{ item.value }}</td>
                         <td v-else-if="item.type=='boolean'">
                              <v-icon color="success">mdi-check</v-icon>
                         </td>
                    </tr>
                    </tbody>
               </template>
          </v-simple-table>

</template>


<script>
 
export default {
    name: 'DetailsTable',
     props:{
          table:{
               type: Array,
               required: true
          }
     },
}

</script>