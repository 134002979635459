
<template>
     <v-container fluid class="dark light--text py-5 px-0" >

<v-container  v-if="model != null" class="light--text py-5 text-center" >
<v-img
     class="mx-auto mb-2"
     width="80%"
     :src="photos[model].url"
     :alt="photos[model].alt"
>
</v-img>
<h4 class="text-center">{{ photos[model].alt }}</h4>
</v-container>
<v-slide-group
     v-model="model"
     class="pa-0"
     active-class="primary"
     circle
     center-active
     dark
     show-arrows
>
     <v-slide-item
     v-for="(photo,n) in photos"
     :key="n"
     v-slot="{ active, toggle }"
     >
     

     <v-card
          :color="active ? undefined : 'grey lighten-1'"
          class="ma-4"
          width="100%"
          max-width="430"
          min-width="230"
          @click="toggle"
     >
          <v-responsive :aspect-ratio="1" max-width="430" class="mx-auto" >
          <v-img
               width="100%"
               :aspect-ratio="1"
               cover
               :src="photo.url"
               :alt="photo.alt"
          >
               <v-row class="fill-height text-center align-content-center align-center" align-center>
                    <v-col>
                         <v-icon
                              v-if="active"
                              color="white"
                              size="50"
                         >mdi-eye</v-icon>
                    </v-col>
               </v-row>
               <span class="px-3 py-3">{{ photo.alt }}</span>
          </v-img>
          </v-responsive>
     </v-card>


     </v-slide-item>
</v-slide-group>

</v-container>
</template>


<script>
 
export default {
    name: 'GalleryPhotos',
     props:{
          photos:{
               type: Array,
               required: true
          }
     },
    data: () => ({
      model:null,
    }),
}

</script>