<template>
     <div id="Single" v-if="apartment">
          <!-- <json-debug :objs="[{apartment:apartment}]"></json-debug> -->
          <v-container fluid class="light--text" :class="apartment.color" >
               <v-container class="mt-5 pt-5">
                    <breadcrumbs light></breadcrumbs>
               </v-container>
               <v-container>
                    <v-row class="text-center bg-brown" >
                         <v-col>
                              <h1 class="text-uppercase my-3">{{apartment.title}}</h1>
                              <p class="">{{apartment.description}}</p>
                         </v-col>
                    </v-row>
               </v-container>
          </v-container>
          <GalleryPhotos :photos="apartment.photos"></GalleryPhotos>
          <v-container fluid class="light">
               <v-container class="mt-5 pt-5">
                   <v-row>
                    <v-col cols="12">
                         <h2 class="text-uppercase">{{ $t('commons.models.apartment') }} {{apartment.title}}</h2>
                         <h3>{{ $t('commons.details') }}</h3>
                    </v-col>
                    <v-col cols="12">
                         <DetailsTable dense :table="apartment.details"></DetailsTable>
                    </v-col>
                   </v-row>
               </v-container>
          </v-container>
          <v-container fluid :class="apartment.color" class="light--text">
               <v-container class="my-5 py-5">
                   <v-row>
                    <v-col cols="12">
                         <h2 class="text-uppercase">{{ $t('commons.price') }} {{apartment.title}}</h2>
                         <p>{{apartment.price.text}}</p>
                         <v-btn large outlined color="light" class="rounded-0 my-5" :to="{name:'contacts'}">
                              <v-icon class="ma-3">mdi-email-fast</v-icon>
                              {{ $t('commons.actions.tobook') }}
                         </v-btn>
                    </v-col>
                    <v-col cols="12" >
                         <PriceTables :tables="apartment.price.tables" />
                    </v-col>
                   </v-row>
               </v-container>
          </v-container>

          <v-container fluid class="light">
               <v-container class="mt-5 pt-5">
                   <v-row>
                    <v-col cols="12" >
                         <v-responsive :aspect-ratio="1/1" id="squarePlan" ref="squarePlan" max-width="720" class="mx-auto" >
                              <v-card class="fill-height text-center" v-if="zoomeSize" :class="apartment.color">
                                   <ZoomableImage :width="zoomeSize" :photo="'/img/assets/apartments/'+apartment.slug+'/plan.png'"></ZoomableImage>
                              </v-card>
                         </v-responsive>
                    </v-col>
                   </v-row>
               </v-container>
          </v-container>
     </div>
</template>



<script>
  import GalleryPhotos from '@/components/Layout/Sections/GalleryPhotos.vue';
  import ZoomableImage from '@/components/Layout/Sections/ZoomableImage.vue'; 
  import DetailsTable from '@/components/Layout/Sections/DetailsTable.vue'; 
  import PriceTables from '@/components/Layout/Sections/PriceTables.vue'; 

  import {mapGetters} from "vuex";

  export default {
    name: 'SingleApartments',
     components:{
          GalleryPhotos,
          ZoomableImage,
          DetailsTable,
          PriceTables
     },
     computed: {
     ...mapGetters({
          apartment: 'apartments/single',
          loading: 'loading/loading',
      }),
     },
    data: () => ({
      model:null,
      zoomeSize: null,
    }),
    created() {
          
          console.log('this.apartment', this.apartment)

          if(!this.apartment){
               this.getData()
          }else if(this.apartment.slug != this.$route.params.slug){
               this.getSingle(this.$route.params.slug)
          }

          

     },
     mounted(){
          if(this.apartment){
               this.zoomeSize = this.getSize()
          }
     },
    methods:{
     async getData(){
          await this.$store.dispatch('apartments/getData').then(() => {
               this.getSingle(this.$route.params.slug)
          })
     },
     async getSingle(slug){
          await this.$store.dispatch('apartments/getSingle', slug).then(()=>{
               this.zoomeSize = this.getSize()
          })
     },
     getSize () {
          console.log('getElementById',document.getElementById('squarePlan').clientHeight);
          console.log('refs',this.$refs.squarePlan);
          return document.getElementById('squarePlan').clientHeight;
     },
     toggle(item){
          console.log(item)
     },
     isEven(n) {
          return n % 2 == 0;
     },
     isOdd(n) {
          return Math.abs(n % 2) == 1;
     }
    }
  }
</script>