<template>

     <v-row>
          <v-col cols="12" md="3" v-for="(table,i) in tables" :key="i">
               <v-card dark style="background-color: transparent;" class="elevation-3">
                    <v-card-title>
                        {{ table.season }}
                    </v-card-title>
                    <v-card-text>
                         <v-simple-table dene dark style="background-color: transparent;">
                              <template v-slot:default>
                                   <thead></thead>
                                   <tbody>
                                   <tr
                                        v-for="(item,label) in table"
                                        :key="label"
                                   >
                                        <template v-if="label != 'season'">
                                        <th>{{label }}</th>
                                        <td >{{ item }}</td>
                                        </template>
                                   </tr>
                                   </tbody>
                              </template>
                         </v-simple-table>
                    </v-card-text>
               </v-card>
              
          </v-col>
     </v-row>
     

</template>


<script>

export default {
name: 'PriceTables',
props:{
     tables:{
          type: Array,
          required: true
     }
},
}

</script>