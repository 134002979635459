<template>
     <div id="List">
          <v-container fluid class="secondary light--text" >
               <v-container class="mt-5 pt-5">
                    <breadcrumbs light></breadcrumbs>
               </v-container>
               <v-container class="py-5">
                    <v-row class="text-center bg-brown" >
                         <v-col cols="12" md="8" class="mx-auto">
                              <h1 class="text-uppercase my-3">{{apartments.title}}</h1>
                              <p class="">{{apartments.description}}</p>
                         </v-col>
                    </v-row>
               </v-container>
          </v-container>
          <v-progress-linear
               v-if="loading"
               :color="(loading) ? 'primary' : 'grey'"
               :indeterminate="loading"
          ></v-progress-linear>
          <v-container fluid class="dark light--text" >
               <v-row class="text-center">
                    <!-- <json-debug :objs="[{list:list}]"></json-debug> -->
                    <v-col v-for="(apartment, n) in list" :key="apartment.slug+n">
                         <v-btn color="light" outlined text :href="'#'+apartment.slug" class="dark light--text" >
                              <h2 class="text-uppercase">{{apartment.title}}</h2>
                         </v-btn>
                    </v-col>
               </v-row>
          </v-container>
          <v-container fluid v-for="(apartment, n) in list" :key="apartment.slug" :class="apartment.color" class="light--text px-0 py-0" :id="apartment.slug">
                    <v-row no-gutters class="align-content-center align-center px-0 py-0">
                         <v-col cols="12" lg="5" class="text-center" :class="(isOdd(n) ? 'order-md-2' : 'order-md-1')" >
                              <v-img
                              width="100%"
                              :src="apartment.image.url"
                              :alt="apartment.image.alt"
                              ></v-img>
                         </v-col>
                         <v-col cols="12" lg="7" class="text-center text-start px-5 py-5" :class="(isOdd(n) ? 'order-md-1' : 'order-md-2')">
                              <v-container class="px-5 py-5 mx-auto">
                                   <h2 class="text-uppercase display-2 strong text-Anton">{{apartment.title}}</h2>
                                   <h4>{{apartment.size}}</h4>
                                   <v-divider class="light my-3"></v-divider>
                                   <p>
                                        {{ apartment.description }}
                                   </p>
                                   <v-btn outlined color="light" class="rounded-0" @click="goToSingle(apartment)" >{{ $t('commons.actions.come-in') }}</v-btn>
                              </v-container>
                         </v-col>
                    </v-row>
          </v-container>
     </div>
</template>


<script>
  import {mapGetters} from "vuex";
 
  export default {
     name: 'ListApartments',
     computed: {
     ...mapGetters({
          list: 'apartments/list',
          loading: 'loading/loading',
      }),
     },
    data: () => ({
      apartments: {},
    }),
    created() {
        this.apartments = this.$i18n.t('apartments')

        console.log('this.list.length',this.list.length)
        if(!this.list.length){
          this.getData()
        }

     },
    methods:{
     async getData(){
          await this.$store.dispatch('apartments/getData')
     },
     goToSingle(apartment){
          this.$store.dispatch('apartments/setSingle',apartment)
          console.log(apartment.slug)
          this.$router.push({name:'apartments.single',params:{slug:apartment.slug}}) 
     },
     isEven(n) {
          return n % 2 == 0;
     },
     isOdd(n) {
          return Math.abs(n % 2) == 1;
     }
    }
  }
</script>