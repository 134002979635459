<template>
     <div ref="contZoom">
          <PinchScrollZoom
               ref="zoomer"
               :width="width"
               :height="width"
               :scale="scale"
               @scaling="scalingHandler"
               >
               <img :src="photo" :width="width"/>
          </PinchScrollZoom>
     </div>
</template>

<script>
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";

export default {
    name: 'ZoomableImage',
    components: {
     PinchScrollZoom,
     },
     props:{
          photo:{
               type: String,
               required: true
          },
          width:{
               type: Number,
               required: true
          }
     },
    data: () => ({
      scale: 0.91,
    }),
    methods: {
     scalingHandler(e ) {
      console.log('scale:',e.scale);
     },
    reset() {
      this.$refs.zoomer.setData({
        scale: 1,
        originX: 0,
        originY: 0,
        translateX: 0,
        translateY: 0        
      });
    }
  }
}

</script>